<template>
    <div>
        <div v-if="authUserPermission['work-report-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4 mt--7">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-4">
                                <h3 class="mb-0">{{ tt('export_work_report') }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <base-input :label="tt('material_number')" :name="tt('material_number')" :placeholder="tt('material_number')" v-model="inputMaterialNumber.material_number"></base-input>
                                <a :href="apiUrl+'work-report/export?material_number='+inputMaterialNumber.material_number+'&token='+token" target="_blank" class="col-12 mt--3 btn btn-primary">Export as Excel</a>
                            </div>
                            <div class="col-6 text-center">
                                <img src="/img/smartcat/work-report.png" class="py-2" style="width: 170px">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import materialNumber from '@/services/master/materialNumber.service';
    import workReport from '@/services/report/workReport.service';
    import baseApiUrl from '@/configs/config';

    export default {        
        data() {
            return {         
                inputMaterialNumber: {
                    material_number: ''
                },  
                dataMaterialNumber: {},  
                dataWorkReport: {}, 
                token: localStorage.token,
                apiUrl: baseApiUrl.apiUrl,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() {
                let context = this;
                Api(context, materialNumber.get()).onSuccess(function(response) {
                    context.dataMaterialNumber = response.data.data
                    console.log(response)
                }).call();
            },
            exportWorkReport() {
                let context = this;
                Api(context, workReport.export_work_report({material_number: context.inputMaterialNumber.material_number})).onSuccess(function(response) {
                    context.dataWorkReport = response.data.data
                    console.log(response)
                }).call();
            },
        }   
    };
</script>
<style></style>
